<span matSnackBarLabel>
	{{ data }}
</span>
<div
	matSnackBarActions
	class="snackbar-actions"
>
	<button
		class="snackbar-close"
		type="button"
		matSnackBarAction
		(click)="snackBarRef.dismissWithAction()"
	>
		<ion-icon icon="close-outline"></ion-icon>
	</button>
</div>
