import { Injectable, inject } from '@angular/core';
import { addIcons } from 'ionicons';
import { alertCircle, closeOutline } from 'ionicons/icons';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@wndr/common/shared/components/snackbar/snackbar.component';

/** Notification type. */
export type NotificationType = 'warning' | 'success';

/** Notification service. */
@Injectable({ providedIn: 'root' })
export class NotificationService {
	private readonly snackbar = inject(MatSnackBar);

	public constructor() {
		addIcons({ closeOutline, alertCircle });
	}

	/**
		* Notify user.
		* @param type Notification type.
		* @param message Notification message.
		* @param durationMs Notification duration in milliseconds.
		*/
	public notify(type: NotificationType, message = '', durationMs = 3000): void {
		this.snackbar.openFromComponent(SnackbarComponent, {
			data: message,
			duration: durationMs,
			verticalPosition: 'top',
			panelClass: ['snackbar', `snackbar_${type}`],
		});
	}
}
