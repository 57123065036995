import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarAction, MatSnackBarActions, MatSnackBarLabel, MatSnackBarRef } from '@angular/material/snack-bar';
import { closeOutline } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { IonIcon } from '@ionic/angular/standalone';

/** Snackbar component. */
@Component({
	selector: 'wndrc-snackbar',
	standalone: true,
	imports: [MatSnackBarLabel, MatSnackBarActions, MatSnackBarAction, IonIcon],
	templateUrl: './snackbar.component.html',
	styleUrl: './snackbar.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackbarComponent {

	/** Reference to current snackbar instance. */
	protected readonly snackBarRef = inject(MatSnackBarRef);

	/** Snackbar message. */
	protected readonly data: string = inject(MAT_SNACK_BAR_DATA);

	public constructor() {
		addIcons({ closeOutline });
	}
}
